import React, { FC, useMemo } from 'react';
import { NextSeo } from 'next-seo';
import { useRouter } from '@/middleware/i18n';
import {
  useSubDomainContext,
} from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import getClientLink from '@/lib/helpers/getClientLink';
import { buildAlternateLinks } from '@/seo/seo.utils/buildAlternateLinks';
import { removeQueryParams } from '@/controllers/analytics/analytics.utils/removeQueryParams';

interface Props {
  supportedDomains?: string[];
}

export const LandingSeoTags: FC<Props> = (props) => {
  const {
    supportedDomains,
  } = props;

  const { asPath } = useRouter();
  const { subDomain, language } = useSubDomainContext();

  const currentPathWithoutParams = useMemo(() => {
    const path = removeQueryParams(asPath);

    if (path === '/') {
      return '';
    }

    return path;
  }, [asPath]);

  const currentUrl = getClientLink(
    currentPathWithoutParams,
    { domain: subDomain, language },
  );

  const languageAlternates = buildAlternateLinks({
    path: currentPathWithoutParams,
    currentDomain: subDomain,
    currentLanguage: language,
    supportedDomains,
  });

  return (
    <NextSeo
      canonical={currentUrl}
      languageAlternates={languageAlternates}
      noindex={false}
    />
  );
};
