import {
  useSubDomainContext,
} from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';

export const useSubDomainRefundWeeks = (refundWeeks?: number | null) => {
  const { t } = useTranslation([I18N_CODES.course]);

  const { subDomain } = useSubDomainContext();

  if (refundWeeks) {
    return refundWeeks;
  }

  const PREPAID_COURSES_REFUND_WEEKS = Number(t(`${I18N_CODES.course}:part_time_courses_refund_weeks|${subDomain}`)) || null;
  const DEFAULT_PREPAID_COURSES_REFUND_WEEKS = 12;

  return PREPAID_COURSES_REFUND_WEEKS || DEFAULT_PREPAID_COURSES_REFUND_WEEKS;
};
